import React from "react"
import { useAnalytics } from "../../../hooks/useAnalytics"

export const withImageContentCarousel = Component => ({
  name = "ImageContentCarousel",
  analytics = {},
  title,
  button,
  images,
  ratio,
  itemSpacing,
  initialLoadCount,
  _key,
}) => {
  const id = _key

  const {
    trackPromoImpression,
    trackPromoClick,
    VisibilitySensor,
  } = useAnalytics()

  let computedItemSpacing = [0, 0]
  switch (itemSpacing) {
    case "line":
      computedItemSpacing = [2, 2]
      break
    case "no_gap":
      computedItemSpacing = [0, 0]
      break
    case "default":
    default:
      computedItemSpacing = [16, 30]
      break
  }

  Component.displayName = name
  return (
    <VisibilitySensor
      onChange={visible => visible && trackPromoImpression({ ...analytics })}
    >
      <Component
        title={title}
        id={id}
        button={button}
        images={images}
        ratio={ratio}
        itemSpacing={computedItemSpacing}
        initialLoadCount={initialLoadCount}
        handlePromoClick={() => trackPromoClick({ ...analytics })}
      />
    </VisibilitySensor>
  )
}
